import React, {useState} from 'react';
import Logout from '../authentication/Logout';
import { isCoownership, isProvider, useUser } from '../contexts/UserContext';
import logo from '../assets/images/trustymoWhite.png'; 

const Menu = ({connected}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const user = useUser(); 

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="bg-primary shadow-lg p-4">
      <div className="container mx-auto">
        <div className="md:flex justify-between items-center">
          <div className="flex justify-between items-center">
            <a href='https://trustymo.com'> 
              <img src={logo} alt="logo Trustymo" className='h-10 object-cover'/>
            </a>

            <div className="md:hidden">
              {/* Bouton de menu pour les écrans mobiles */}
              <button className="text-white" onClick={toggleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {menuOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
          

          <div className={`md:flex md:space-x-4 ${menuOpen ? 'block' : 'hidden'} mt-4 md:mt-0`}>
            {connected ? (
              <>
                {isCoownership(user.user) || isProvider(user.user) ? (
                  <a
                    href="/coownership"
                    className="block text-white hover:text-secondary transition duration-300 mb-2"
                  >
                    Mes copropriétés
                  </a>
                ) : null }
                <a
                  href="/dashboard"
                  className="block text-white hover:text-secondary transition duration-300 mb-2"
                >
                  Tableau de bord
                </a>
                <div className="block md:inline mb-2">
                  <Logout />
                </div>
              </>
            ) : (
              <>
                <a
                  href="/login"
                  className="block text-white hover:text-secondary transition duration-300"
                >
                  Connexion
                </a>
              </>
            )}
          </div>

        </div>
      </div>
    </nav>
  );
  
};

export default Menu;