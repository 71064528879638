import moment from "moment";
import 'moment/locale/fr';

/**
 * Permet de transformer un nombre de secondes en format date compréhensible
 * @param {*} timestamp 
 * @returns 
 */
export const dateFromTimestampToString = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("ll"); //L
};

export const dateFromTimestampToHtmlFormat = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("YYYY-MM-DD"); //L
};

export const datetimeFromTimestampToString = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.format("LLL"); //L
};

/**
 * Permet de transformer un nombre de secondes en format heure 
 * @param {*} timestamp 
 * @returns 
 */
 export const timeFromTimestampToString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  
  // Obtenir l'heure en heure française
  return date.toLocaleTimeString("fr-FR", {
    timeZone: "Europe/Paris",
    hour: "2-digit",
    minute: "2-digit",
  });
};