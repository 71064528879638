import React from 'react';

const TimeInput = ({ id, label, value, onChange, error, required=true }) => {
  return (
    <div className="mb-4">
      <div className='flex'>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {required ? (
          <label className='text-red-500'> *</label>
        ) : null}
      </div>
      
      <input
        id={id}
        type="time"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required
      />

      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default TimeInput;