import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, orderBy } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { datetimeFromTimestampToString } from '../librairies/Dates';
import TextModal from '../components/modals/TextModal';

const db = getFirestore(app);

const ListEvents = () => {

  const [events, setEvents] = useState([]); 
  const [loader, setLoader] = useState(true);

  const [showTextModal, setShowTextModal] = useState(false); 
  const [descriptionToShow, setDescriptionToShow] = useState(null); 

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/events");

    // Ajouter la condition where
    const q = query(collectionRef, where("status", "==", "accepted"), orderBy("dateBegining", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((event) => {
          let obj = {
            id: event.id, 
            title: event.data().title, 
            description: event.data().description, 
            firstname: event.data().firstnameCreator,
            lastname: event.data().lastnameCreator,
            dateBegining: event.data().dateBegining.seconds,
            dateEnd: event.data().dateEnd.seconds,
          }

          arr.push(obj);

        });
        
        setEvents(arr);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe claims");
      unsubscribe();
    }
  }, []); 


  useEffect(() => {
    if(showTextModal === false){
      setDescriptionToShow(null);
    }
  }, [showTextModal]);

  useEffect(() => {
    if(descriptionToShow != null){
      setShowTextModal(true); 
    }
  }, [descriptionToShow]);


  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Titre
                </th>
                <th scope="col" className="px-6 py-3">
                    Date Début
                </th>
                <th scope="col" className="px-6 py-3">
                    Date de Fin
                </th>
                <th scope="col" className="px-6 py-3">
                    Prestataire
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, key) => {
                return (
                  <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                    <td className="px-6 py-4">
                      {event.title}
                    </td>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {datetimeFromTimestampToString(event.dateBegining)}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {datetimeFromTimestampToString(event.dateEnd)}
                    </th>
                    <td className="px-6 py-4">
                      {event.firstname + " " + event.lastname}
                    </td>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={() => setDescriptionToShow(event.description)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Afficher la description</a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {showTextModal ? (
        <TextModal onClose={() => setShowTextModal(false)} text={descriptionToShow}/>
      ) : null }
    </div>
  );
};

export default ListEvents;
