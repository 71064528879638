import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, doc, updateDoc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { fetchData } from '../librairies/Firestore';
import Input from '../components/inputs/Input';
import FileInput from '../components/inputs/FileInput';
import { handleUpload } from '../librairies/FirebaseStorage';
import PrimaryButton from '../components/buttons/PrimaryButton';
import CheckboxInput from '../components/inputs/CheckboxInput';

const db = getFirestore(app);

const ManageCoownership = () => {

  const [coownership, setCoownership] = useState(null); 
  const [loader, setLoader] = useState(true);
  const [loaderModify, setLoaderModify] = useState(false);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [syndicPhone, setSyndicPhone] = useState("");
  const [syndicEmail, setSyndicEmail] = useState("");
  const [syndicName, setSyndicName] = useState("");
  const [residenceName, setResidenceName] = useState("");
  const [image, setImage] = useState(null); 
  const [imageObject, setImageObject] = useState(null);
  //const [totalTantieme, setTotalTantieme] = useState(null); 
  const [links, setLinks] = useState([]); 
  const [chatActivated, setChatActivated] = useState(false); 

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [errors, setErrors] = useState({
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    email: '',
    syndicName: '',
    residenceName: '',
    image: '',
  });

  //contexte de l'utilisateur connecté
  const user = useUser();



  useEffect(() => {
    //On récupère les infos de la copropriété 
    // eslint-disable-next-line 
    getCoownership();
  }, []); 

  /**
   * récupère les informations de la copropriété 
   */
  const getCoownership = async () => {
    
    const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path);

    let data = await fetchData(docRef); 
    
    if(data !== null){
      setCoownership(data); 
      setLoader(false);
    }
  }

  const resetErrors = () => {
    setErrors({
      address: '',
      city: '',
      postalCode: '',
      phone: '',
      email: '',
      syndicName: '',
      residenceName: '',
      image: '',
    }); 
  }

  /**
   * lorsqu'on a récupéré les infos de la copro, on update les states des champs du formulaire
   */
  useEffect(() => {
    if(coownership !== null){
      console.log(coownership)
      setAddress(coownership.address);
      setResidenceName(coownership.name);
      setZip(coownership.zip);
      setCity(coownership.city);
      setSyndicPhone(coownership.phoneSyndic);
      setSyndicEmail(coownership.mailSyndic); 
      setSyndicName(coownership.nameSyndic);
      setImage(coownership.image);
      //setTotalTantieme((coownership.totalTantieme !== undefined && coownership.totalTantieme !== null ) ? coownership.totalTantieme : "");
      setChatActivated((coownership.chatActivated === undefined) || coownership.chatActivated);

      if(coownership.links !== undefined && coownership.links !== null)
        setLinks(coownership.links)
    }

  }, [coownership]); 


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation des champs
    const newErrors = {};

    if (!address) {
      newErrors.address = "L'adresse est requise.";
    }

    if (!city) {
      newErrors.city = "La ville est requise.";
    }

    if (!zip) {
      newErrors.postalCode = "Le code postal est requis.";
    }

    /* ces champs ne sont plus obligatoires
    if (!syndicPhone) {
      newErrors.phone = "Le numéro de téléphone est requis.";
    }

    if (!syndicEmail) {
      newErrors.email = "L'email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(syndicEmail)) {
      newErrors.email = "L'email est invalide.";
    }*/

    if (!syndicName) {
      newErrors.syndicName = "Le nom du gestionnaire est requis.";
    }

    if (!residenceName) {
      newErrors.residenceName = "Le nom de la résidence est requis.";
    }

    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try{
        setLoaderModify(true);
        
        //on envoie les données sur firestore 
        const coownershipRef = doc(db, user.user.userData.lastCoownershipLoaded.path);
    
        await updateDoc(coownershipRef, {
          address: address,
          city: city,
          zip: zip,
          mailSyndic: syndicEmail,
          name: residenceName,
          nameSyndic: syndicName,
          phoneSyndic: syndicPhone,
          chatActivated: chatActivated,
          //totalTantieme: totalTantieme,
          links: links.length > 0 ? links : null
        });

        //l'image a été modifié 
        if(image !== coownership.image){
          
          try{
            let imageUrl = await handleUpload("coownershipPicture/", imageObject);
         
            console.log("image modifiée avec l'url : "+imageUrl);
            console.log(imageUrl);
  
            updateDoc(coownershipRef, {
              image: imageUrl
            });
          
          } catch (err){
            console.log(err);
          }
        }

        setLoaderModify(false);
        setSuccess("Les informations ont bien étées modifiées");
        setError("");
        resetErrors(); 
      } catch(err){
        console.log(err)
        setError("Une erreur est survenue, impossible de modifier les informations");
        resetErrors(); 
        setSuccess("");
        setLoaderModify(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
      setSuccess("");
      setError("");
    }
  };




  /**
   * Modification de l'image de la copropriété
   * @param {*} e 
   */
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      // Vous pouvez effectuer des opérations supplémentaires ici, comme la validation du type de fichier
      setImage(URL.createObjectURL(file));
      setImageObject(file); 
    }
  };

  const handleLinkChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = [...links];
    updatedFields[index][name] = value;
    setLinks(updatedFields);
  };

  const addLink = () => {
    setLinks([...links, { name: '', url: '' }]);
  };

  const removeLink = (index) => {
    const updatedFields = [...links];
    updatedFields.splice(index, 1);
    setLinks(updatedFields);
  };


  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="mx-auto mt-4 p-4 bg-white rounded md:shadow">
          <h2 className="text-2xl text-center mb-4">Infos sur la résidence</h2>
          <form onSubmit={handleSubmit}>

            <div className='mb-6'>
              <p className='font-bold'>Codes</p>

              <div className='border-b border-gray mt-0.5 mb-2'></div>
              
              <p>Transmettez respectivement ces codes aux propriétaires, résidents et prestataires de la copropriété. Si un propriétaire est résident, transmettez lui seulement le code propriétaire. </p>

              <div className='flex flex-wrap justify-center gap-2'>
                <div className='bg-primary md:border-2 shadow-xl rounded-lg px-3 py-2 md:px-12 md:py-6'>
                  <p className='text-black text-center font-bold'>Code propriétaire</p>
                  <p className='text-center text-white text-md md:text-xl font-bold'>{coownership.codeOwner}</p>
                </div>

                <div className='bg-primary md:border-2 shadow-xl rounded-lg px-3 py-2 md:px-12 md:py-6'>
                  <p className='text-black text-center font-bold'>Code résident</p>
                  <p className='text-center text-white text-md md:text-xl font-bold'>{coownership.codeTenant}</p>
                </div>

                <div className='bg-primary md:border-2 shadow-xl rounded-lg px-3 py-2 md:px-12 md:py-6'>
                  <p className='text-black text-center font-bold'>Code prestataire</p>
                  <p className='text-center text-white text-md md:text-xl font-bold'>{coownership.codeProvider}</p>
                </div>
              </div>
            </div>

            <div className='mb-6'>
              <p className='font-bold'>Copropriété</p>

              <div className='border-b border-gray mt-0.5 mb-2'></div>

              <FileInput id={"image"} label={"Image"} name={"image"} file={image} onChange={handleImageChange} error={errors.image} />
              <Input id={"residenceName"} label={"Nom de la résidence"} type={"text"} name={"residenceName"} value={residenceName} onChange={(event) => setResidenceName(event.target.value)} error={errors.residenceName} />
              <Input id={"address"} label={"Adresse"} type={"text"} name={"address"} value={address} onChange={(event) => setAddress(event.target.value)} error={errors.address} />
              <Input id={"zip"} label={"Code Postal"} type={"text"} name={"zip"} value={zip} onChange={(event) => setZip(event.target.value)} error={errors.zip} />
              <Input id={"city"} label={"Ville"} type={"text"} name={"city"} value={city} onChange={(event) => setCity(event.target.value)} error={errors.city} />
              {/*<Input id={"totalTantieme"} label={"Total Tantièmes"} type={"number"} name={"totalTantieme"} value={totalTantieme} onChange={(event) => setTotalTantieme(event.target.value)} error={errors.totalTantieme} required={false}/>*/}
            </div>

            <div className='mb-6'>
              <p className='font-bold'>Gestionnaire</p>

              <div className='border-b border-gray mt-0.5 mb-2'></div>

              <Input id={"syndicName"} label={"Nom du gestionnaire"} type={"text"} name={"syndicName"} value={syndicName} onChange={(event) => setSyndicName(event.target.value)} error={errors.syndicName} />
              <Input id={"syndicEmail"} label={"e-mail du gestionnaire"} type={"email"} name={"syndicEmail"} value={syndicEmail} onChange={(event) => setSyndicEmail(event.target.value)} error={errors.email} required={false}/>
              <Input id={"syndicPhone"} label={"Téléphone du gestionnaire"} type={"phone"} name={"syndicPhone"} value={syndicPhone} onChange={(event) => setSyndicPhone(event.target.value)} error={errors.phone} required={false} />
            </div>

            <div className='mb-6'>
              <p className='font-bold'>Liens extranet (facultatif)</p>

              <div className='border-b border-gray mt-0.5 mb-2'></div>
              
              <p>Ajoutez des liens si vous souhaitez les rendre disponibles aux membres de la résidence. Ils apparaitront sur la fiche immeuble de l'application</p>

              <div className="container mx-auto p-4">
                {links !== undefined && links.length > 0 ? (
                  links.map((field, index) => (
                    <div key={index} className="mb-4 md:flex items-center">
                      <input
                        type="text"
                        name="name"
                        value={field.name}
                        placeholder="Nom"
                        onChange={(e) => handleLinkChange(index, e)}
                        className="w-48 p-2 border border-gray-400 rounded-lg mb-2"
                      />
                      <input
                        type="link"
                        name="url"
                        value={field.url}
                        placeholder="Lien"
                        onChange={(e) => handleLinkChange(index, e)}
                        className="w-48 p-2 border border-gray-400 rounded-lg md:ml-4 mb-2"
                      />
                      <button
                        type="button"
                        onClick={() => removeLink(index)}
                        className="bg-red-500 text-white p-2 rounded-lg md:ml-4 mb-2"
                      >
                        Supprimer le lien
                      </button>
                    </div>
                  ))
                ) : null }

                <button
                  type="button"
                  onClick={addLink}
                  className="bg-secondary text-white p-2 rounded-lg"
                >
                  + Ajouter un lien
                </button>
              </div>
            </div>


            <div className='mb-6'>
              <p className='font-bold'>Chat Messagerie</p>

              <div className='border-b border-gray mt-0.5 mb-2'></div>

              <CheckboxInput id={"chatActivated"} label={"Activation du chat"} name={"chatActivated"} checked={chatActivated} onChange={() => setChatActivated(!chatActivated)}/>
            </div>
            
            <div className="text-center">
              <PrimaryButton type={"submit"} value={"Modifier"} loading={loaderModify} />
            </div>

            {success !== "" ? (
              <p className='justify-center flex my-1 text-green-500'>{success}</p>
            ) : null}

            {error !== "" ? (
              <p className='justify-center flex my-1 text-red-500'>{error}</p>
            ) : null}
          </form>
        </div>
      )}

    </div>

  );
};

export default ManageCoownership;
