import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, doc, updateDoc, onSnapshot } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';

const db = getFirestore(app);

const ListUsersProvider = () => {

  const [usersProvider, setUsersProvider] = useState([]); 
  const [loader, setLoader] = useState(true);

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/usersProvider");

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((owner) => {
          let obj = {
            id: owner.id, 
            firstname: owner.data().firstname, 
            lastname: owner.data().lastname, 
            email: owner.data().email,
            phone: owner.data().phone,
          }

          arr.push(obj);

        });

        setUsersProvider(arr);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe");
      unsubscribe();
    }
  }, []); 

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Prénom
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Téléphone
                </th>
              </tr>
            </thead>
            <tbody>
              {usersProvider.length > 0 ? 
                usersProvider.map((provider, key) => {
                  return (
                    <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {provider.firstname}
                      </th>
                      <td className="px-6 py-4">
                        {provider.lastname}
                      </td>
                      <td className="px-6 py-4">
                        {provider.email}
                      </td>
                      <td className="px-6 py-4">
                        {provider.phone}
                      </td>
                    </tr>
                  )
                })
              : null }
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ListUsersProvider;
