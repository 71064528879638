import { collection, doc, getFirestore, addDoc, Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { isProvider, useUser } from '../contexts/UserContext';
import FileInput from '../components/inputs/FileInput';
import { handleUpload } from '../librairies/FirebaseStorage';
import TextareaInput from '../components/inputs/TextareaInput';
import TimeInput from '../components/inputs/TimeInput';
import { dateFromTimestampToString } from '../librairies/Dates';

const db = getFirestore(app);

function AddEvent({onClose, date}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [filename, setFilename] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [typeFile, setTypeFile] = useState(null);

  const [timeBegining, setTimeBegining] = useState(""); 
  const [timeEnd, setTimeEnd] = useState("");

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    file: '',
    timeBegining: '',
    timeEnd: ''
  });

  const resetState = () => {
    setError("");
    setTitle("");
    setDescription("");
  }

  /**
   * On crée le document billboard
   * @param {*} fileUrl 
   */
  const createDoc = async (fileUrl) => {
    //on envoie les données sur firestore 
    const eventsCollection = collection(db, user.user.userData.lastCoownershipLoaded.path+"/events");

    try{
      await addDoc(eventsCollection, {
        title: title,
        description:description,
        dateSave: Timestamp.fromDate(new Date()), 
        dateBegining: combineDateAndTime(timeBegining),
        dateEnd: combineDateAndTime(timeEnd),
        firstnameCreator: isProvider(user.user) ? "le prestataire "+user.user.userData.firstname : "le gestionnaire", 
        lastnameCreator: isProvider(user.user) ? user.user.userData.lastname : "", 
        status: "accepted",
        idCreator: doc(db, 'users/' + user.user.user.uid),
        typeMedia: typeFile, 
        linkMedia: fileUrl,
      });
    } catch(err){
      setError("Une erreur est survenue, impossible d'ajouter l'événement");
    }
  }

  // Fonction pour ajouter l'heure sélectionnée à la date et obtenir un timestamp
  const combineDateAndTime = (time) => {
    const [hours, minutes] = time.split(':');
  
    // Création de la date en heure française
    const combinedDate = new Date(date);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    combinedDate.setSeconds(0);
    combinedDate.setMilliseconds(0);
  
    // Convertir la date en heure française en utilisant le fuseau Europe/Paris
    const combinedDateInFrance = new Date(
      combinedDate.toLocaleString("en-US", { timeZone: "Europe/Paris" })
    );
  
    return Timestamp.fromDate(combinedDateInFrance); // Conversion en Firestore Timestamp
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    
    // Validation des champs
    const newErrors = {};

    if (!title) {
      newErrors.title = "Le titre est requis.";
    }

    if (!description) {
      newErrors.description = "La description est requise.";
    }

    if (!timeBegining) {
      newErrors.timeBegining = "L'heure de début est requise.";
    }

    if (!timeEnd) {
      newErrors.timeEnd = "L'heure de fin est requise.";
    }


    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try{
        setLoader(true);
        
        if(typeFile !== null && fileObject !== null){
          //un fichier souhaite être inséré
          try{
            let fileUrl = await handleUpload("events/", fileObject);

            createDoc(fileUrl);
            
          } catch (err){
            console.log(err);
            setError("Une erreur est survenue, impossible d'ajouter l'événement");
            setLoader(false);
            return; 
          }
        }else{
          createDoc(null); 
        }

        setLoader(false);
        //on remet tous les states par défaut
        resetState(); 

        //on close la popup
        onClose(); 

      } catch(err){
        console.log(err)
        setError("Une erreur est survenue, impossible d'ajouter l'événement");
        setLoader(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
    }
  };

  
  /**
   * Modification de l'image de la copropriété
   * @param {*} e 
   */
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);

      if(file.type === "application/pdf"){
        setTypeFile("pdf"); 
      }else{
        setTypeFile("image"); 
      }

      // Vous pouvez effectuer des opérations supplémentaires ici, comme la validation du type de fichier
      setFilename(file.type === "application/pdf" ? file.name : URL.createObjectURL(file));
      setFileObject(file); 
    }
  };


  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Ajouter un événement le {dateFromTimestampToString(Math.floor(date.getTime()/1000))}</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>

                <div className='border-b border-gray mt-0.5 mb-2'></div>
                  <div className='flex justify-between'>
                    <TimeInput id={"timeBegining"} label={"heure de début"} value={timeBegining} onChange={(time) => setTimeBegining(time)} error={errors.timeBegining}/>
                    <TimeInput id={"timeEnd"} label={"heure de fin"} value={timeEnd} onChange={(time) => setTimeEnd(time)} error={errors.timeEnd}/>
                  </div>

                  <Input id={"title"} label={"Titre"} type={"text"} name={"title"} value={title} onChange={(event) => setTitle(event.target.value)} error={errors.title} />
                  <TextareaInput id={"description"} label={"Description"} name={"description"} value={description} onChange={(event) => setDescription(event.target.value)} error={errors.description} />
                  <FileInput id={"affichage"} name={"affichage"} label={"Image ou PDF"} file={filename} onChange={handleFileChange} error={errors.file} fileAccepted={"image/*,application/pdf"} type={typeFile} />
                </div>

                <div className="text-center">
                  <PrimaryButton type={"submit"} value={"Ajouter"} loading={loader} />
                </div>

                {error !== "" ? (
                  <p className='justify-center flex my-1 text-red-500'>{error}</p>
                ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default AddEvent;