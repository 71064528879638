import React, {useState} from 'react';
import ManageCoownership from '../coownership/ManageCoownership';
import ListOwner from '../members/ListOwner';
import ListProviders from '../providers/ListProviders';
import ListClaims from '../claims/ListClaims';
import ListBillboard from '../billboard/ListBillboard';
import ListTenant from '../members/ListTenant';
import { isAdmin, isCoownership, isProvider, useUser } from '../contexts/UserContext';
import ListCoownerships from '../coownership/ListCoownerships';
import ListUsersProvider from '../members/ListUsersProvider';
import ListEvents from '../events/ListEvents';
import CalendarEvents from '../events/CalendarEvents';

const Dashboard = () => {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [menu, setMenu] = useState("residence"); 
  const [menuOpen, setMenuOpen] = useState(false);

  console.log("contect user : ");
  console.log(user.user.userData);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const renderSwitchMenu = () => {
    switch(menu){
      case "residence": 
        return (
          <ManageCoownership />
        );
      case "prestataires": 
        return (
          <ListProviders />
        ); 
      case "coproprietaires": 
        return (
          <ListOwner />
        ); 
      case "residents": 
        return(
          <ListTenant />
        ); 
      case "prestatairesInscrits": 
        return(
          <ListUsersProvider />
        ); 
      case "affichages": 
        return (
          <ListBillboard />
        ); 
      case "reclamations": 
        return (
          <ListClaims />
        ); 
      case "evenements": 
        return (
          <ListEvents />
        ); 
      default: 
        return (
          <ManageCoownership />
        );
    }
  }

  const renderSwitchMenuAdmin = () => {
    switch(menu){
      case "coproprietes": 
        return (
          <ListCoownerships />
        );
      default: 
        return (
          <ListCoownerships />
        );
    }
  }

  const renderSwitchMenuProvider = () => {
    switch(menu){
      case "affichages": 
        return (
          <ListBillboard />
        ); 
      case "evenements": 
        return (
          <CalendarEvents />
        ); 
      default: 
        return (
          <ListBillboard />
        ); 
    }
  }

  return (
    <div className='md:h-screen flex justify-center items-center bg-gray-100'>
      {isCoownership(user.user) ? (
        <div className='w-full flex flex-col md:flex-row md:w-5/6 md:h-5/6 bg-white p-8 rounded-lg shadow-md'>
          {/* Menu latéral */}
  
          <div className="md:hidden mb-4">
            <button className="text-primary font-bold" onClick={toggleMenu}>
              {menuOpen ? "Fermer Menu" : "Ouvrir Menu"}
            </button>
          </div>

          <aside className={`w-full md:w-1/6 bg-primary p-4 md:rounded-l-lg shadow-md ${menuOpen ? 'block' : 'hidden'} md:block`}>
            <h1 className="text-white text-2xl mb-4">Tableau de bord</h1>
            <ul className="space-y-2">
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("residence")}>Résidence</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("prestataires")}>Prestataires</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("coproprietaires")}>Copropriétaires</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("residents")}>Résidents</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("prestatairesInscrits")}>Prestataires inscrits</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("affichages")}>Affichages</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("reclamations")}>Demandes</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("evenements")}>Événements</li>
            </ul>
          </aside>
  
          {/* Contenu principal */}
          <main className="w-full md:w-5/6 p-4 overflow-auto bg-white p-4 md:rounded-r-lg md:shadow-md md:border-gray md:border-y md:border-r">
            {renderSwitchMenu()}
          </main>
        </div>
      ) : null }

      {isAdmin(user.user) ? (
        <div className='w-full flex md:w-5/6 md:h-5/6 bg-white md:p-8 rounded-lg md:shadow-md'>
          {/* Menu latéral */}
  
          <aside className="hidden md:block w-1/6 bg-primary p-4 rounded-l-lg shadow-md">
            <h1 className="text-white text-2xl mb-4">Tableau de bord Administrateur</h1>
            <ul className="space-y-2">
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("coproprietes")}>Copropriétés</li>
            </ul>
          </aside>
  
          {/* Contenu principal */}
          <main className="w-full md:w-5/6 p-4 overflow-auto bg-white p-4 rounded-r-lg shadow-md border-gray border-y border-r">
            {renderSwitchMenuAdmin()}
          </main>
        </div>
      ) : null }

      {isProvider(user.user) ? (
        <div className='w-full flex flex-col md:flex-row md:w-5/6 md:h-5/6 bg-white p-8 rounded-lg shadow-md'>
          {/* Menu latéral */}
  
          <div className="md:hidden mb-4">
            <button className="text-primary font-bold" onClick={toggleMenu}>
              {menuOpen ? "Fermer Menu" : "Ouvrir Menu"}
            </button>
          </div>

          <aside className={`w-full md:w-1/6 bg-primary p-4 md:rounded-l-lg shadow-md ${menuOpen ? 'block' : 'hidden'} md:block`}>
            <h1 className="text-white text-2xl mb-4">Tableau de bord Prestataire</h1>
            <ul className="space-y-2">
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("affichages")}>Affichages</li>
              <li className="text-white hover:text-secondary cursor-pointer" onClick={() => setMenu("evenements")}>Événements</li>
            </ul>
          </aside>
  
          {/* Contenu principal */}
          <main className="w-full md:w-5/6 p-4 overflow-auto bg-white p-4 md:rounded-r-lg md:shadow-md md:border-gray md:border-y md:border-r">
            {renderSwitchMenuProvider()}
          </main>
        </div>
      ) : null }

    </div>
  );
};

export default Dashboard;
